import { Injectable, computed, inject, signal } from '@angular/core';
import { SearchQuery } from '../models/search-query.model';
import { SearchAPIService } from './search-api.service';
import { catchError, of, tap } from 'rxjs';
import { StacItemCollection } from '../models/stac-item-collection.model';

class SearchState {
  itemCollection: StacItemCollection = new StacItemCollection({});
  error: string = '';
  isLoading: boolean = false;
}

@Injectable({
  providedIn: 'root',
})
export class SearchStateService {
  private searchAPIService = inject(SearchAPIService);
  private _currentState = signal<SearchState>(new SearchState());

  performSearch(url: string, type: 'POST' | 'GET', queryParams: SearchQuery) {
    this._currentState.set({
      ...new SearchState(),
      isLoading: true,
    });

    this.searchAPIService
      .search(url, type, queryParams)
      .pipe(
        tap((items) => {
          this._currentState.set({
            itemCollection: new StacItemCollection(items),
            error: '',
            isLoading: false,
          });
        }),
        catchError((error) => {
          this._currentState.set({
            itemCollection: new StacItemCollection({}),
            error: error.message,
            isLoading: false,
          });
          return of({ error: error });
        })
      )
      .subscribe();
  }

  clear() {
    this._currentState.set(new SearchState());
  }

  itemCollection = computed(() => this._currentState().itemCollection);
  items = computed(() => this._currentState().itemCollection.items);
  isSearching = computed(() => this._currentState().isLoading);
  hasError = computed(() => this._currentState().error !== '');
}
