import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StacItemCollection } from '../models/stac-item-collection.model';
import { SearchQuery } from '../models/search-query.model';
import { geoJSON } from 'leaflet';

@Injectable({
  providedIn: 'root',
})
export class SearchAPIService {
  private http = inject(HttpClient);

  search(url: string, type: 'POST' | 'GET', query: SearchQuery): Observable<StacItemCollection> {
    if (type === 'GET') {
      let params = new HttpParams().set('limit', query.limit.toString());
      if (query.datetime.length > 0) {
        if (query.datetime[0]) {
          let dtStr = new Date(query.datetime[0]).toISOString();
          if (query.datetime[1]) {
            dtStr += '/' + new Date(query.datetime[1]).toISOString();
          } else {
            dtStr += '/..';
          }
          params = params.set('datetime', dtStr);
        } else {
          if (query.datetime[1]) {
            let dtStr = '../';
            dtStr += new Date(query.datetime[1]).toISOString();
            params = params.set('datetime', dtStr);
          }
        }
      }
      if (query.bbox.length > 0) {
        params = params.set('bbox', query.bbox.toString());
      }
      // if (query.intersects) {
      //   params = params.set('intersects', encodeURIComponent(query.intersects.toString()));
      // }
      if (query.collections.length > 0) {
        params = params.set('collections', query.collections.toString());
      }
      if (query.items.length > 0) {
        params = params.set('ids', query.items.toString());
      }

      return this.http.get<StacItemCollection>(url, { params: params });
    }

    let queryJSON: any = {};

    if (query.datetime.length > 0) {
      if (query.datetime[0]) {
        let dtStr = new Date(query.datetime[0]).toISOString();
        if (query.datetime[1]) {
          dtStr += '/' + new Date(query.datetime[1]).toISOString();
        } else {
          dtStr += '/..';
        }
        queryJSON.datetime = dtStr;
      } else {
        if (query.datetime[1]) {
          let dtStr = '../';
          dtStr += new Date(query.datetime[1]).toISOString();
          queryJSON.datetime = dtStr;
        }
      }
    }

    if (query.intersects) {
      const geoJsonObject = JSON.parse(query.intersects);
      if (geoJsonObject.type === 'FeatureCollection') {
        queryJSON.intersects = geoJsonObject.features[0].geometry;
      } else if (geoJsonObject.type === 'Feature' || geoJsonObject.type === 'Item') {
        queryJSON.intersects = geoJsonObject.features[0].geometry;
      } else {
        queryJSON.intersects = geoJsonObject;
      }
    } else {
      if (query.bbox.length > 0) {
        queryJSON.bbox = query.bbox;
      }
    }

    if (query.collections.length > 0) {
      queryJSON.collections = query.collections;
    }
    if (query.items.length > 0) {
      queryJSON.ids = query.items;
    }

    queryJSON.limit = query.limit;

    return this.http.post<StacItemCollection>(url, queryJSON);
  }
}
